<template>
  <div class="common-file">
    <div class="banner">
      <img src="../../assets/images/commonfile-banner.png" alt />
    </div>
    <div class="container">
      <div class="content">
        <h1 class="content-title">伊莉丝钻石产品订购协议及条款细则</h1>
        <h2>一、订购协议</h2>
        <p>（1）我清楚我提供的碳源样本会被寄送至公司指定碳化实验室进行成分分析及碳元素的提纯，用来制作专属自己的伊莉丝钻石。我授权塞维利亚（北京）文化科技有限公司以及他的合作伙伴运输、使用并消耗我提供的头发等所有碳源样本。</p>
        <p>（2）我同意全款支付或支付商品出售金额的60%作为定金，允许伊莉丝在订单生效之日起90天内交货，同时承诺在被通知定制产品配送信息后支付剩余的40%尾款。我同意塞维利亚（北京）文化科技有限公司以邮件或短信形式、或在官网指定碳源证书查询位置输入订单号的方式提供产品相关碳源证书或“4C”评级认证证明的资料后支付剩余40%尾款，公司在确认尾款到账后以物流形式寄往购买者在伊莉丝官方平台上预留的收货地址。</p>
        <p>（3）我知晓在订单下达后的三日内（下单日为第一日），拥有要求公司退还全部或部分定金并归还碳源样本的权利。订单下达三日后，由于样本已经寄出并很有可能开始了制作过程，所以我不得以任何理由要求退还定金，同时也不要求退还碳源样本。</p>
        <p>（4）我理解由于钻石培养和成品切割打磨过程中的不可控因素，伊莉丝只能把成品钻石控制在一定重量范围内（X≤0.05ct)。并且，我知道并同意成品钻石的规格有可能超出或小于目标规格。如果小于目标规格则按实际订购规格的费用执行；如果高于目标规格，我不需要支付额外的费用。</p>
        <p>（5）我清楚由于碳源样本的差异，每一颗伊莉丝彩色钻石在颜色深度上会有少许差异，我同意只要成品钻石属于我订购的颜色（黄色、蓝色、粉色、绿色），我不会以颜色深浅有差异为理由要求退货、重做或者拒收。</p>
        <p>（6）我知道由于此专属钻石不具有对其他客户销售的意义，所以我订制的钻石只要达到了伊莉丝承诺给我的钻石等级标准（能被国际珠宝鉴定机构认定的钻石，颜色种类符合，净度SI或以上，是指定切割形状），我不会以任何理由拒绝取货、要求退货或者要求重做。</p>
        <p>（7）如果我在世界权威机构鉴定出自己定制的伊莉丝钻石为非钻石，伊莉丝同意按我购买商品金额的100%进行赔偿。</p>
        <p>（8）如订单出现意外情况（战争、自然灾害等不可抗力除外）导致伊莉丝公司无法履行交货义务，我接受塞维利亚（北京）文化科技有限公司的赔付，赔付标准不超过定金总额。</p>
        <h2>二、产品条款</h2>
        <p>伊莉丝品牌承诺：</p>
        <p>每一颗伊莉丝钻石都与天然矿采钻石有着相同的物理性质、光学效果和化学成分，并且钻石的碳元素来自客户提供的生物组织样本。</p>
        <p>每一颗伊莉丝都有终身的质量保证，任何正常使用范围内出现的问题我公司都将提供免费或有偿解决方案。</p>
        <p>（注：因为高温加热，故意磨损，失窃等人为原因造成的问题不在保证范围之内）</p>
        <h2>三、配送条款</h2>
        <p>我们保证谨慎对待每一份收到的头发样本并精心制作每一颗伊莉丝钻石。塞维利亚（北京）文化科技有限公司承诺：</p>
        <p>（1）货品包装</p>
        <p>为满足顾客需求，我们对售出的每颗钻石配以专用包装。选配钻饰的顾客送珠宝首饰盒、钻石擦拭布、手提袋等精美包装。</p>
        <p>（2）线上配送</p>
        <p>为保证货品安全，我们一律采用顺丰速运进行配送，确保您的产品万无一失，安全稳妥地送到您手中。我们为所有定单的商品提供专业快递及全程保价，无论您的订单金额多少，您不必为商品支付任何运费。</p>
        <p>（3）发货时间</p>
        <p>伊莉丝钻石由于其订制的特性，成品商品一般在收到客户碳源样本起60天内发出（具体发货时间以实际订购产品为准）。</p>
        <p>（4）到货时间</p>
        <p>自公司发出产品后，北京地址次日到达，非北京城市一般在3日内到达客户手中。</p>
        <p>（5）自取方式</p>
        <p>客户在收到取货通知后，可以凭订单号和身份证在指定销售点上门取货。</p>
        <p>（6）注意事项</p>
        <p>订单中默认的收货地址若为单位地址，我们会在工作日为您送达，如遇双休日、法定节假日到货时间将会顺延；如收货地址为家庭地址，则不受此限制。</p>
        <h2>四、支付条款</h2>
        <p>（1）在线支付</p>
        <p>在您提交订单后，并得到客服代表确认后，您需要支付订单的订金，我们为您提供伊莉丝钻石官网支付宝或微信在线支付。</p>
        <p>（2）支付提醒：</p>
        <p>为确保您的资金安全，让您获得更好的服务体验，线上交易遇到问题时请及时与我们联系，联系方式可以致电公司客服010-56984858或客服经理电话：18510663765。</p>
        <h2>五、退款条款</h2>
        <p>（1）我们承诺三日内无条件退款政策（自付款日起）使您有充裕的时间考虑，确保您做出满意的选择。</p>
        <p>（2）若您在我们公司订购的产品出现以下问题（例如钻石检测为非钻石、钻石变色、褪色等等），可以在收到货品15天内（以快递签收单上的签收日期为准）退还购买货品。我们将依据《中华人民共和国消费者权益保护法》、《中华人民共和国产品质量法》及其他相关法规，为您提供周到的退换货服务，塞维利亚15天退货承诺为您无忧购物提供保障和信心。</p>
        <p>（3）由于伊莉丝钻石取自个人组织作为碳源订制，不具有二次销售的意义价值，在客户确认钻石标准并进行定购的情况下，除非成品未达到客户订单确认的钻石标准，或出现严重质量问题，否则公司均不接受退货。</p>
        <p>（4）因退货运输过程中发生的丢失和损坏，公司概不负责，敬请谅解。</p>
        <h2>六、免责条款</h2>
        <p>任何经过佩戴、自行改动过或调整过尺寸的、或者以人为方式损坏的货品，若满足以上任意一种情况的，我们均不接受退货；无运输保险和错误包装的货品，我们无法提供退货承诺服务。</p>
        <p>本条款细则内容的一切最终解释权归塞维利亚（北京）文化科技有限公司所有。</p>
        <footer>塞维利亚（北京）文化科技有限公司</footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonFile",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style lang="less" scoped>
.common-file {
  .banner {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    width: 1000px;
    margin: 60px auto 200px;
    .title {
      font-size: 34px;
      line-height: 1;
      margin-bottom: 30px;
      color: #333;
      text-align: center;
    }
    .sub-title {
      margin-bottom: 44px;
      font-size: 18px;
      color: #999;
      text-align: center;
    }
    .label {
      width: 100%;
      padding: 30px 160px 28px;
      text-align: center;
      color: #666;
      border: 1px solid #cfcfcf; /*no*/
      border-bottom-width: 4px; /*no*/
      margin-bottom: 66px;
    }
    .content-title {
      font-size: 24px;
      color: #333;
      margin-bottom: 40px;
      text-align: center;
    }
    .content {
      h2 {
        margin-top: 30px;
      }
      p {
        font-size: 16px;
        line-height: 1.8;
        color: #666;
        margin-top: 10px;
        text-indent: 2em;
      }
      footer {
        margin-top: 30px;
        text-align: right;
      }
    }
  }
}
</style>
